import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
// material
import {MatDialog} from '@angular/material/dialog';
// components
import {ConfirmActionComponent} from '@shared/components/dialogs/confirm-action/confirm-action.component';
// services
import {BaseHttpService} from '@shared/services/base-http.service';
import {AuthService} from '@auth/services/auth.service';
import {AppStateService} from '@shared/services/app-state.service';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {DistrictAndSchoolDataService} from '@shared/services/district-and-school-data.service';
import {TranslationService} from '@shared/modules/translations/services/translation.service';
import {NotificationService} from '@shared/modules/notification-dialogs/services/notification.service';
// models
import {CurrentUserModel, IDistrictUserPermission, ISchoolUserPermission} from '@shared/models/current-user.model';
import {Workspaces} from '@shared/models/resource-types.model';
import {IUserPermissions} from '@shared/models/user-role-permissions.model';
import {UserRoles} from '@shared/models/user-types.model';
import {SupportPlanPermissions} from '@school/models/teacher-support-plan-permissions.model';
import {DistrictRoutes, SchoolRoutes} from '@shared/models/routes-config.model';
import {IConfirmActionDialogData} from '@shared/components/dialogs/confirm-action/models/confirm-action-dialog-data.model';
import {NotificationTypes} from '@shared/modules/notification-dialogs/models/notification-types.model';
// helpers
import {getDistrictRoute, getSchoolRoute} from '@shared/utils/route-config.helper';

@Injectable({ providedIn: 'root' })
export class PermissionsService extends BaseHttpService<any> {

  public permissionInitComplete$ = new BehaviorSubject<boolean>(false);

  public userSimulationChangeInProgress$ = new Subject<boolean>();

  constructor(
    http: HttpClient,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly appStateService: AppStateService,
    private readonly dataService: DistrictAndSchoolDataService,
    private readonly translationService: TranslationService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    super(http);
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  // 1. get current user w/o simulation ID
  // 2. If ADMIN -> get current user with simulation ID (if present)
  // 3. Else -> Open corresponding page

  public initPermissions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.authService.isAuthenticated) {
        resolve();
        return;
      }

      this.authService.getCurrentUser()
        .pipe(take(1))
        .subscribe(
          (response: CurrentUserModel) => {
            this._initPermissions(response);

            if (!!response.is_super && !!this.localStorageService.getSimulationUserID) {
              this.startUserSimulation(this.localStorageService.getSimulationUserID).then(() => {
                resolve();
                this.permissionInitComplete$.next(true);
              });
            } else {
              resolve();
              this.permissionInitComplete$.next(true);
              window.removeEventListener('storage', this.storageEventListener.bind(this));
            }
          },
          (err: HttpErrorResponse) => {
            if (err.status === 401) {
              resolve();
            } else {
              reject(err);
            }

            this.permissionInitComplete$.next(true);
          }
        );
    });
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event.key === this.localStorageService.simulatingUserKey) {
        if (event.oldValue !== event.newValue) {
          window.location.reload();
        }
        // if (!!event.newValue) {
        //   this.startUserSimulation(this.localStorageService.getSimulationUserID);
        // } else {
        //   this.stopUserSimulation(false);
        // }
      }
    }
  }

  public startUserSimulation(userID: number): Promise<void> {
    return new Promise((resolve) => {
      this.userSimulationChangeInProgress$.next(true);
      this.localStorageService.setSimulationUserID = userID;
      this.appStateService.startUserSimulation(userID);

      this.authService.getCurrentUser()
        .pipe(take(1))
        .subscribe(
          (user) => {
            if (userID !== user.id) {
              // Simulation user ID is not matching current user's data (ex reason: log in with school user, add simulationID in localStorage)
              this.localStorageService.clear();
              this.authService.logout();
              this.notificationService.showNotification(NotificationTypes.WARNING, 'State is invalid, please login again');
              return resolve();
            }

            this._initPermissions(user);
            this.permissionInitComplete$.next(true);

            if (user.district_level) {
              if (!!user.district.suspended_at) {
                this.dialog.open<ConfirmActionComponent, IConfirmActionDialogData>(ConfirmActionComponent, {
                  width: '500px',
                  data: {
                    title: 'Action not possible',
                    message: 'This user is only assigned to one District which is suspended',
                    submitBtnText: 'Close',
                    showCancelBtn: false
                  }
                });
                this.stopUserSimulation(false);
                return;
              }

              if (this.workspace === Workspaces.ADMIN) {
                this.router.navigate([getDistrictRoute(user.district.id, DistrictRoutes.home)]);
              }
            } else {
              // If user is assigned to one school and this school is suspended => Just show warning message
              if (this.appStateService.currentUserPermissions.schools.length === 1) {
                const school = this.appStateService.currentUserPermissions.schools[0];

                if (!!school.suspended_at) {
                  this.dialog.open<ConfirmActionComponent, IConfirmActionDialogData>(ConfirmActionComponent, {
                    width: '500px',
                    data: {
                      title: 'Action not possible',
                      message: 'This user is only assigned to one school which is suspended',
                      submitBtnText: 'Close',
                      showCancelBtn: false
                    }
                  });
                  this.stopUserSimulation(false);
                  return;
                }

                if (this.workspace === Workspaces.ADMIN) {
                  this.router.navigate([getSchoolRoute(user.district.id, school.id, SchoolRoutes.home)]);
                }

              } else {
                if (this.workspace === Workspaces.ADMIN) {
                  this.router.navigate([getSchoolRoute(user.district.id, null, SchoolRoutes.selectSchool)]);
                }
              }

            }

            this.userSimulationChangeInProgress$.next(false);
            resolve();
          }
        );
    });
  }

  public stopUserSimulation(navigateToRoot = true): void {
    this.userSimulationChangeInProgress$.next(true);
    this.appStateService.stopUserSimulation();

    this.localStorageService.removeSimulationUserID();
    this.permissionInitComplete$.next(true);
    this.userSimulationChangeInProgress$.next(false);


    this.initPermissions()
      .then(() => {
        if (navigateToRoot) {
          this.router.navigate(['/']);
        }
      })
      .catch(() => {
        window.location.reload();
      });
  }

  private _initPermissions(response: CurrentUserModel): void {
    this.appStateService.currentUser = response;
    this.appStateService.currentUser.full_name = this.appStateService.currentUser.full_name || `${this.appStateService.currentUser.first_name} ${this.appStateService.currentUser.last_name}`;

    if (response.is_super) {
      // Super Admin
      this.appStateService.currentUserPermissions = {
        userType: Workspaces.ADMIN
      };
    } else if (response.district_level) {
      // District Level User
      this.appStateService.currentUserPermissions = {
        userType: Workspaces.DISTRICT,
        permissions: {
          role: response.district.user_type,
          isLiaison: response.district.is_liaison,
          canManageSchools: response.district.can_add_edit_schools,
          canManageStaff: response.district.can_add_edit_staff
        }
      };

      if (response.district.user_type === UserRoles.District_Staff) {
        this.setPermissionsPerSchool(response.schools);
      }
    } else {
      // School Level User
      this.appStateService.currentUserPermissions = {
        userType: Workspaces.SCHOOL,
        permissions: {}
      };

      this.setPermissionsPerSchool(response.schools);
    }

    this.appStateService.currentDate = new Date(response.current_date);

    if (this.appStateService.currentDate.getMonth() > 6) {
      this.appStateService.currentSchoolYear = `${this.appStateService.currentDate.getFullYear()}-${this.appStateService.currentDate.getFullYear() + 1}`;
    } else {
      this.appStateService.currentSchoolYear = `${this.appStateService.currentDate.getFullYear() - 1}-${this.appStateService.currentDate.getFullYear()}`;
    }

    this.localStorageService.setUserType(this.appStateService.currentUserPermissions.userType);
  }

  private setPermissionsPerSchool(schools: any[]): void {
    this.appStateService.currentUserPermissions.schools = schools;

    schools.map(school => {
      this.appStateService.currentUserPermissions.permissions[school.id] = {
        role: school.user_type,
        supportPlanPermission: school.support_plan_permission
      };
    });
  }

  public hasPermission(permissions: IUserPermissions): boolean {
    // If nothing was passed to check - return true
    if (!permissions || ((!permissions.userRoles || !permissions.userRoles.length) && !permissions.specificRole)) {
      return true;
    }

    if (permissions.specificRole) {
      return this.hasSpecificRole(permissions.specificRole);
    }

    let hasPermission = false;

    switch (this.appStateService.currentUserPermissions.userType) {
      case Workspaces.ADMIN:
        return !permissions.blockAdmin;
      case Workspaces.DISTRICT:
        // District Staff is one of: School Leader, School Teacher, School Staff -> judge them as school users
        if (this.workspace === Workspaces.SCHOOL && 'role' in this.appStateService.currentUserPermissions.permissions && this.appStateService.currentUserPermissions.permissions.role === UserRoles.District_Staff) {
          hasPermission = this.checkSchoolPermissions(permissions);
        } else {
          hasPermission = this.checkDistrictPermissions(permissions);
        }
        break;
      case Workspaces.SCHOOL:
        hasPermission = this.checkSchoolPermissions(permissions);
        break;
    }

    return permissions.anythingBut ? !hasPermission : !!hasPermission;
  }

  private hasSpecificRole(role: UserRoles): boolean {
    if (!role) {
      return true;
    }

    const workspace = this.appStateService.currentUserPermissions.userType;

    let hasPermission = false;

    switch (workspace) {
      case Workspaces.ADMIN:
        hasPermission = role === UserRoles.Super_Admin;
        break;
      case Workspaces.DISTRICT:
        // District Staff is one of: School Leader, School Teacher, School Staff -> judge them as school users
        if (this.workspace === Workspaces.SCHOOL && 'role' in this.appStateService.currentUserPermissions.permissions && this.appStateService.currentUserPermissions.permissions.role === UserRoles.District_Staff) {
          hasPermission = this.checkSchoolPermissions({ userRoles: [role] });
        } else {
          hasPermission = this.checkDistrictPermissions({ userRoles: [role] });
        }
        break;
      case Workspaces.SCHOOL:
        hasPermission = this.checkSchoolPermissions({ userRoles: [role] });
        break;
    }

    return hasPermission;
  }

  public hasSpecificRoleInOneOfSchools(role: UserRoles): boolean {
    if (!role) {
      return true;
    }

    const workspace = this.appStateService.currentUserPermissions.userType;
    const currentUserPermissions = this.appStateService.currentUserPermissions.permissions as IDistrictUserPermission;

    if (workspace === Workspaces.ADMIN) {
      return true
    } else {
      const hasSchoolTeacherRole = Object.keys(currentUserPermissions).some(key => {
        if (!isNaN(Number(key))) {
          return currentUserPermissions[key].role === role;
        }

        return false;
      });

      return hasSchoolTeacherRole;
    }
  }

  private checkDistrictPermissions(permissions: IUserPermissions): boolean {
    const allowedUserRoles = permissions.userRoles;
    const extraPermissions = permissions.extraPermissions;

    const currentUserPermissions = this.appStateService.currentUserPermissions.permissions as IDistrictUserPermission;

    if (allowedUserRoles.includes(currentUserPermissions.role)) {
      if (currentUserPermissions.role === UserRoles.District_Leader && !!extraPermissions) {
        return this.checkDistrictExtraPermissions(extraPermissions);
      }

      return true;
    }

    return false;
  }

  private checkSchoolPermissions(permissions: IUserPermissions): boolean {
    const allowedUserRoles = permissions.userRoles;
    const extraPermissions = permissions.extraPermissions;

    // pathname example value: /school/districtID/schoolID/...rest
    const schoolID = this.dataService.schoolID || +window.location.pathname.split('/')[3];

    // If cannot retrieve school id neither from dataService nor from browser url
    if (!schoolID) {
      return false;
    }

    const currentUserPermissions = (this.appStateService.currentUserPermissions.permissions as ISchoolUserPermission)[schoolID];
    // Just in case
    if (!currentUserPermissions) {
      return false;
    }
    if (allowedUserRoles.includes(currentUserPermissions.role)) {
      if (!!extraPermissions) {
        return this.checkSchoolExtraPermissions(extraPermissions);
      }

      return true;
    }

    return false;
  }

  private checkDistrictExtraPermissions(extraPermissions: { canManageSchools?: boolean; canManageStaff?: boolean }): boolean {
    const currentUserPermissions = this.appStateService.currentUserPermissions.permissions;

    let value = true;

    // if ('isLiaison' in extraPermissions) {
    //   value = value && !!(currentUserPermissions as IDistrictUserPermission).isLiaison;
    // }

    if ('canManageSchools' in extraPermissions) {
      value = value && !!(currentUserPermissions as IDistrictUserPermission).canManageSchools;
    }

    if ('canManageStaff' in extraPermissions) {
      value = value && !!(currentUserPermissions as IDistrictUserPermission).canManageStaff;
    }

    return value;
  }

  private checkSchoolExtraPermissions(extraPermissions: { supportPlanPermissions?: SupportPlanPermissions[] }): boolean {
    const schoolID = this.dataService.schoolID || +window.location.pathname.split('/')[3];

    // If cannot retrieve school id neither from dataService nor from browser url
    if (!schoolID) {
      return false;
    }

    const currentUserPermissions = (this.appStateService.currentUserPermissions.permissions as ISchoolUserPermission)[schoolID];
    return extraPermissions.supportPlanPermissions.some(ipp => ipp === currentUserPermissions.supportPlanPermission);
  }

  private get workspace(): Workspaces | null {
    const url = window.location.pathname;
    if (url.startsWith('/admin')) {
      return Workspaces.ADMIN;
    }
    if (url.startsWith('/district')) {
      return Workspaces.DISTRICT;
    }
    if (url.startsWith('/school')) {
      return Workspaces.SCHOOL;
    }

    return null;
  }
}
