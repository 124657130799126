import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// models
import { Languages } from '@shared/modules/translations/models/languages.enum';

// translations
import { getLanguageTranslations } from '@shared/modules/translations/configs';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  public languageChange = new BehaviorSubject<Languages>(Languages.EN);

  public currentLanguage: Languages;

  private languageLocalStorageKey = 'ctmrLanguage';

  private localTranslations: { [key: string]: { [key in Languages]: any } } = {};

  constructor(
    private readonly route: ActivatedRoute,
  ) {
    const languageFromRoute = route.snapshot.queryParams.language;
    if (languageFromRoute && (languageFromRoute === Languages.EN || languageFromRoute === Languages.FR)) {
      this.setLanguage(languageFromRoute);
    } else {
      const lang = localStorage.getItem(this.languageLocalStorageKey);
      if (lang && (lang === Languages.EN || lang === Languages.FR)) {
        this.setLanguage(lang as Languages);
      } else {
        this.setLanguage(Languages.EN);
      }
    }
  }

  public setLanguage(language: Languages): void {
    if (this.currentLanguage !== language) {
      this.currentLanguage = language;
      this.languageChange.next(language);
      localStorage.setItem(this.languageLocalStorageKey, language);
    }
  }

  // forceLanguage is only used for SUPER level now
  public translate(key: string, localTranslationKey?: string, forceLanguage?: Languages): string {
    return this.getTranslatedText(key, localTranslationKey, forceLanguage);
  }

  public isTranslationExist(localTranslationKey: string) {
    return localTranslationKey in this.localTranslations
  }

  private getTranslatedText(key: string, localTranslationKey?: string, forceLanguage?: Languages): string {
    const lang = forceLanguage || this.currentLanguage;

    try {
      const paths = key.split('.');
      let tmp;

      if (localTranslationKey) {
        tmp = this.localTranslations[localTranslationKey][lang][paths[0]];
      } else {
        tmp = getLanguageTranslations(lang)[paths[0]];
      }

      for (let i = 1; i < paths.length; i++) {
        tmp = tmp[paths[i]];
      }

      if (typeof tmp !== 'string') {
        return localTranslationKey ? this.getTranslatedText(key) : `${key} NOT_TRANSLATED_1`;
      }

      return tmp;
    } catch (ex) {
      return localTranslationKey ? this.getTranslatedText(key) : `${key} NOT_TRANSLATED_2`;
    }
  }

  public setLocalTranslations(key: string, translations: { [key in Languages]: any }): void {
    if (this.localTranslations[key]) {
      throw new Error(`Local translation with key ${key} is already registered!`);
    } else {
      this.localTranslations[key] = translations;
    }
  }

  public setMultipleLocalTranslations(key: string, translations: Array<{ [key in Languages]: any }>): void {
    if (this.localTranslations[key]) {
      throw new Error(`Local translation with key ${key} is already registered!`);
    } else {
      const combinedTranslations: { [key in Languages]: any } = {
        [Languages.EN]: {},
        [Languages.FR]: {}
      };

      for (const translationConfig of translations) {
        this.mergeDeep(combinedTranslations[Languages.EN], translationConfig[Languages.EN]);
        this.mergeDeep(combinedTranslations[Languages.FR], translationConfig[Languages.FR]);
      }

      this.localTranslations[key] = combinedTranslations;
    }
  }

  public unsetLocalTranslations(key: string): void {
    if (this.localTranslations[key]) {
      delete this.localTranslations[key];
    }
  }

  private mergeDeep(target: {[key: string]: any}, source: {[key: string]: any}): void {
    if ((target && typeof target === 'object') && (source && typeof source === 'object')) {
      for (const key in source) {
        if (source.hasOwnProperty(key) && source[key] && typeof source[key] === 'object') {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }
}
